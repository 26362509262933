<template>
    <div class="content-template">
      <!-- 第一页：首页封面 -->
        <div class="card" id="card1">
          <div class="card_box_one height-100">
            <div class="title" id="card1_title">{{chainDetail.sIndustry}}产业链</div>
            <div class="border-box" id="card1_border_line">
              <div class="border-line"></div>
            </div>
            <div class="date-time" id="card1_date_time">{{contentDetail && contentDetail.sCreateTime && contentDetail.sCreateTime.split(" ")[0]}}</div>
            <div class="bg1—1" id="card1_bg1"></div>
            <div class="bg1-2" id="card1_bg2"></div>
          </div>
        </div>
        <!-- 内容 -->
        <div class="audio-box">
          <audio src="audio/Bridge.mp3" ref="bgAudio"></audio>
          <audio :src="audio1Src" ref="audio1"></audio>
          <audio :src="audio2Src" ref="audio2"></audio>
          <audio :src="audio3Src" ref="audio3"></audio>
          <audio :src="audio4Src" ref="audio4"></audio>
          <audio :src="audio5Src" ref="audio5"></audio>
          <audio :src="audio6Src" ref="audio6"></audio>
        </div>
        <!-- 第二页：产业链图形 -->
        <div class="card page_fadeIn none" id="card2">
          <div class="card_two_box height-100">
            <div class="card2_header">
              <div class="title2 verticle-center">{{chainDetail.sIndustry}}产业链基本形态如图</div>
            </div>
            <div class="card2_content page_fadeIn">
              <div class="chain-pic" 
              v-if="(Array.prototype.isPrototypeOf(chainDetail.vUpIndustryChainData)&&chainDetail.vUpIndustryChainData.length > 0) || (Array.prototype.isPrototypeOf(chainDetail.vDownIndustryChainData)&&chainDetail.vDownIndustryChainData.length > 0)">
                <div class="chain-top" v-if="Array.prototype.isPrototypeOf(chainDetail.vUpIndustryChainData)&&chainDetail.vUpIndustryChainData.length > 0">
                  <span v-for="(item, i) in chainDetail.vUpIndustryChainData" :key="item+i" >{{item}}</span>
                </div>
                <div v-else class="chain-top">
                  <span>供应商</span>
                </div>
                <div class="chain-mid">
                  <div class="chain-mid-box">
                    <div class="upstream-box">
                      <div class="upstream-left"></div>
                      <div class="upstream-right">上游</div>
                    </div>
                    
                    <div class="production">{{chainDetail.sIndustry}}</div>
                    <div class="downstream-box">
                      <div class="downstream-left"></div>
                      <div class="downstream-right">下游</div>
                    </div>
                  </div>
                </div>
                <div class="chain-btm" v-if="Array.prototype.isPrototypeOf(chainDetail.vDownIndustryChainData)&&chainDetail.vDownIndustryChainData.length > 0">
                  <span v-for="(item, i) in chainDetail.vDownIndustryChainData" :key="item+i">{{item}}</span>
                </div>
                <div class="chain-btm" v-else >
                  <span>终端消费者</span>
                </div>
                <!-- <div class="title">{{contentDetail.sTitle}}</div> -->
              </div>
            </div>
            <div class="bg2-1"></div>
            <div class="bg2-2"></div>
          </div>
        </div>
        <!-- 第三页：相关个股 -->
        <div class="card page_fadeIn none" id="card3">
          <div class="card_three_box height-100">
            <div class="card3_header">
              <div class="title3 verticle-center">产业链投资标的推荐</div>
            </div>
            <div class="card3_stock_info .page_fadeIn">
              <div class="top" v-if="recommendDetail1.stGoalPriceData">
                <div class="stock-name">
                  <div class="name">{{recommendDetail1.stGoalPriceData.sSecuAbbr}}</div>
                  <div class="code">{{recommendDetail1.stGoalPriceData.sSecuCode}}</div>
                </div>
                <div class="stock-price-increase">
                  <div class="stock-price">
                    <span class="name">目标价</span>
                    <span class="code" v-if="recommendDetail1.stGoalPriceData.fGoalPriceMin.toFixed(2) ===  recommendDetail1.stGoalPriceData.fGoalPriceMax.toFixed(2)">{{recommendDetail1.stGoalPriceData.fGoalPriceMax.toFixed(2)}}</span>
                    <span class="code" v-else>{{recommendDetail1.stGoalPriceData.fGoalPriceMin.toFixed(2)}}-{{recommendDetail1.stGoalPriceData.fGoalPriceMax.toFixed(2)}}</span>
                  </div>
                  <div class="stock-increase">
                    <span class="name">目标涨幅</span>
                    <span class="code" v-if="recommendDetail1.stGoalPriceData.fMinPtc === recommendDetail1.stGoalPriceData.fMaxPtc">{{ (recommendDetail1.stGoalPriceData.fMaxPtc*100).toFixed(2) }}%</span>
                    <span class="code" v-else>{{(recommendDetail1.stGoalPriceData.fMinPtc*100).toFixed(2)}}%-{{ (recommendDetail1.stGoalPriceData.fMaxPtc*100).toFixed(2) }}%</span>
                  </div>
                </div>
                
              </div>
              <div class="bottom">
                <div class="text">从{{companyName1 | companyNameFilter}}证券报告来看，当前{{stockName1}}的市盈率和每股收益预测如下：</div>
              </div>
            </div>
            <div class="analyse-chart" >
              <div class="chart-one">
                <div class="chart-title">市盈率</div>
                <div id="surplusRate1" class="chart-box"></div>
              </div>
              <div class="chart-two">
                <div class="chart-title">每股收益</div>
                <div id="earnings1" class="chart-box"></div>
              </div>
              <div class="data-source">数据来源：{{companyName1  | companyNameFilter}}证券研究报告</div>
            </div>
            <div class="bg3-1"></div>
            <div class="bg3-2"></div>
          </div>
        </div>
        <!-- 第四页：相关个股 -->
        <div class="card page_fadeIn none" id="card4">
          <div class="card_four_box height-100">
            <div class="card4_header">
              <div class="title4 verticle-center">产业链投资标的推荐</div>
            </div>
            <div class="card4_stock_info .page_fadeIn">
              <div class="top" v-if="recommendDetail2.stGoalPriceData">
                <div class="stock-name">
                  <div class="name">{{recommendDetail2.stGoalPriceData.sSecuAbbr}}</div>
                  <div class="code">{{recommendDetail2.stGoalPriceData.sSecuCode}}</div>
                </div>
                <div class="stock-price-increase">
                  <div class="stock-price">
                    <span class="name">目标价</span>
                    <span class="code" v-if="recommendDetail2.stGoalPriceData.fGoalPriceMin.toFixed(2) ===  recommendDetail2.stGoalPriceData.fGoalPriceMax.toFixed(2)">{{recommendDetail2.stGoalPriceData.fGoalPriceMax.toFixed(2)}}</span>
                    <span class="code" v-else>{{recommendDetail2.stGoalPriceData.fGoalPriceMin.toFixed(2)}}-{{recommendDetail2.stGoalPriceData.fGoalPriceMax.toFixed(2)}}</span>
                  </div>
                  <div class="stock-increase">
                    <span class="name">目标涨幅</span>
                    <span class="code" v-if="recommendDetail2.stGoalPriceData.fMinPtc === recommendDetail2.stGoalPriceData.fMaxPtc">{{ (recommendDetail2.stGoalPriceData.fMaxPtc*100).toFixed(2) }}%</span>
                    <span class="code" v-else>{{(recommendDetail2.stGoalPriceData.fMinPtc*100).toFixed(2)}}%-{{ (recommendDetail2.stGoalPriceData.fMaxPtc*100).toFixed(2) }}%</span>
                  </div>
                </div>
              </div>
              <div class="bottom">
                <div class="text">从{{companyName2 | companyNameFilter}}证券报告来看，当前{{stockName1}}的市盈率和每股收益预测如下：</div>
              </div>
            </div>
            <div class="analyse-chart" >
              <div class="chart-one">
                <div class="chart-title">市盈率</div>
                <div id="surplusRate2" class="chart-box"></div>
              </div>
              <div class="chart-two">
                <div class="chart-title">每股收益</div>
                <div id="earnings2" class="chart-box"></div>
              </div>
              <div class="data-source">数据来源：{{companyName2  | companyNameFilter}}证券研究报告</div>
            </div>
            <div class="bg4-1"></div>
            <div class="bg4-2"></div>
          </div>
        </div>
        <!-- 第五页：政策解读 -->
        <div class="card page_fadeIn none" id="card5">
          <div class="card_five_box height-100" id="card5Container">
            <div class="card5_header page_fadeIn none" id="card5_title">
              <div class="title5 verticle-center">产业链投资解读</div>
            </div>
            <div class="card5_content page_fadeIn none" id="card5_text">
              <p class="text" v-for="(item,index) in contentDetail.vPolicy" :key="index"  v-html="item"></p>
            </div>
            <div class="bg5-1"></div>
            <div class="bg5-2"></div>
          </div>
        </div>
        <!-- 第六页：尾页封面 -->
        <div class="card page_fadeIn none" id="card6">
          <div class=" card_six_box width-100 height-100">
            <div class="title6  none" id="card6_title">产业链</div>
            <div class="content-text page_zoomIn none" id="card6_text">
              <div class="text1 page_zoomIn">
                <span class="text-num">200+</span>
                <span>产业链主题</span>
              </div>
              <div class="text1 page_zoomIn page_delay">
                <span class="text-num">5000+</span>
                <span>产业链上下游节点</span>
              </div>

            </div>
            <div class="bg6-1 page_fadeIn" id="card6_bg1"></div>
            <div class="bg6-2 page_fadeIn" id="card6_bg2"></div>
            <div class="bg6-3 page_fadeIn" id="card6_bg3"></div>
            <div class="bg6-4 page_fadeIn" id="card6_bg4"></div>
            <div class="bg6-5 page_fadeIn" id="card6_bg5"></div>
          </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import { NumberEvent } from "../../utils/number";
import { GetParams2 } from '../../utils/common'
import ApiServe from '@/api/index'
    export default {
        name: "industryChain",
        data() {
          return {
            audioLengthArr: [],
            recordTimeAll:null,
            audioData:[],
            id:"",
            model_id:null,
            model_name:"",
            model_category:"",
            ip:"",
            port:"",
            offset_x:"",
            offset_y:"",
            width:"",
            height:"",
            source:"",
            dateTime: "",
            device_id:null,
            real:1, //0测试， 1正式
            time:"",
            contentId: null,//产业链文章id
            contentDetail: {},
            chainDetail:{}, //产业链图谱数据
            recommendList: [], //推荐股票
            recommendDetail1: {},
            recommendDetail2: {},
            firstStockPredict: {},
            secondStockPredict: {},
            stockName1: "",
            stockName2: "",
            companyName1: "",
            companyName2: "",
            sTime:"",
            audio1Src:"",
            audio2Src:"",
            audio3Src:"",
            audio4Src:"",
            audio5Src:"",
            audio6Src:"",
          }
        },
        created () {
          this.id = GetParams2().id || null;
          this.model_id = GetParams2().model_id || 9;
          this.model_name = GetParams2().model_name || null;
          this.model_category = GetParams2().model_category || null;
          this.url_extra_params = GetParams2().url_extra_params || null;
          this.ip = GetParams2().ip || null;
          this.port = GetParams2().port || null;
          this.offset_x = GetParams2().offset_x || null;
          this.offset_y = GetParams2().offset_y || null;
          this.width = GetParams2().width || null;
          this.height = GetParams2().height || null;
          this.source = GetParams2().source || null;
        },
        mounted() {
          // 设置页面标题
          document.title = "产业链短视频";
          console.log("this.url_extra_params",this.url_extra_params)
          console.log("typeof this.url_extra_params",typeof this.url_extra_params);
          if(this.url_extra_params){
            try {
              this.contentId = JSON.parse(this.url_extra_params).content_id;
              console.log("this.contentId",this.contentId);
              console.log("typeof this.contentId",typeof this.contentId);
              if(this.contentId){
                this.getData();
              }
            } catch (error) {
              console.log("error",error)
            }
            
          }
        },
        filters: {
          companyNameFilter(value) {
            if(value.includes('证券')) {
            return value.replace('证券', '')
            }else{
              return value
            }
          }
        },
        computed: {
          allReady() {
            const { audio1Src,audio2Src, audio3Src,audio4Src,audio5Src,audio6Src,audioData} = this;
            return {
              audio1Src,
              audio2Src,
              audio3Src,
              audio4Src,
              audio5Src,
              audio6Src,
              audioData
            };
          },
          newIpPort() {
            if (this.source == "ali") {
              return "https://recordvideoali.wedengta.com";
            } else {
              return "https://recordvideo.wedengta.com";
            }
          },
        },
        watch: {
          allReady(val){
            if(val.audio1Src && val.audio2Src&& val.audio3Src&& val.audio4Src&& val.audio5Src&& val.audio6Src && val.audioData.length === 6){
              if(this.ip && this.port){
                const params = {
                  ip: this.ip,
                  port: this.port,
                  offset_x: this.offset_x,
                  offset_y: this.offset_y,
                  width: this.width,
                  height: this.height,
                };
                ApiServe.recordstartPc(this.newIpPort,params)
                .then((res)=>{
                  console.log("recordstartPc 返回",res);
                  this.load();
                })
                .catch((err)=>{
                  ApiServe.recordreleasePc(this.newIpPort,{
                    ip:this.ip,
                    port:this.port
                  });
                  this.load();
                }) 
              }else{
                alert("ip,port未获取到");
                this.load();
              } 
            }
          }
        },
        methods: {
          getData(){
            let params1 = {
              "stUserInfo": {
                "vGUID": [],
                "sDUA": ""
              },
              "iId": Number(this.contentId)
            }
            let params2 = {
              "stUserInfo": {
                "vGUID": [],
                "sDUA": ""
              },
              "iId": Number(this.contentId),
              "iLimit": 12,
              "iOffset": 0
            }
            // const promise1 = ApiServe.getAbstractPolicy(params1);
            // const promise2 = ApiServe.getStockRecommend(params2);
            // const promise3 = ApiServe.getIndustryChain(params1);
            const promise1 = ApiServe.dtpostapiproxyPc(this.newIpPort,'https://comm.mdengta.com/?s=IndustryChainMachineWrite&f=getTitleAbstractPolicy&req=GetTitleAbstractPolicyReq&rsp=GetTitleAbstractPolicyRsp',params1);
            const promise2 = ApiServe.dtpostapiproxyPc(this.newIpPort,'https://comm.mdengta.com/?s=IndustryChainMachineWrite&f=getStockRecommend&req=GetStockRecommendReq&rsp=GetStockRecommendRsp',params2);
            const promise3 = ApiServe.dtpostapiproxyPc(this.newIpPort,'https://comm.mdengta.com/?s=IndustryChainMachineWrite&f=getIndustryChain&req=GetIndustryChainReq&rsp=GetIndustryChainRsp',params1);
            Promise.all([promise1, promise2, promise3]).then((values) => {
              console.log(values);
              let res0 = values[0];
              let res1 = values[1];
              let res2 = values[2];
              // 处理摘要政策
              if(res0.ret === 0){
                let str0 = res0.content;
                let result0 = JSON.parse(str0);
                this.contentDetail = Object.assign({},result0);
                console.log("摘要返回：",this.contentDetail);
                this.contentDetail.vAbstract = this.contentDetail.sAbstract.split("\n")
                this.contentDetail.vPolicy = this.contentDetail.sPolicy.split("\n")
              } else if(res0.ret === -1){
                console.log('返回标题内容政策为空')
              }
              // 处理荐股数据
              if(res1.ret === 0){
                let str1 = res1.content;
                let result1 = JSON.parse(str1);
                this.recommendList = result1.vOneGoalPriceData;
                console.log("this.recommendList",this.recommendList)
                let firstData = JSON.parse(JSON.stringify(result1.vOneGoalPriceData[0]))
                let secondData = JSON.parse(JSON.stringify(result1.vOneGoalPriceData[1]))
                let date = new Date();
                let nowYear = date.getFullYear();
                let yearStr = String(nowYear).slice(-2) + 'E'
                // 转换图表x 时间轴的数据
                this.recommendDetail1 = this.handleArr(firstData,nowYear);
                this.recommendDetail2 = this.handleArr(secondData,nowYear);
                this.firstStockPredict['targetPrice'] = firstData.stGoalPriceData.fGoalPriceMax;
                this.firstStockPredict['targetPe'] = firstData.vPeEpsData.filter(v => v.fForecastYear === yearStr) ? firstData.vPeEpsData.filter(v => v.fForecastYear === yearStr)[0].fPe : 0;
                this.firstStockPredict['targetEps'] = firstData.vPeEpsData.filter(v => v.fForecastYear === yearStr) ? firstData.vPeEpsData.filter(v => v.fForecastYear === yearStr)[0].fEps : 0; 
                this.secondStockPredict['targetPrice'] = firstData.stGoalPriceData.fGoalPriceMax;
                this.secondStockPredict['targetPe'] = firstData.vPeEpsData.filter(v => v.fForecastYear === yearStr) ? firstData.vPeEpsData.filter(v => v.fForecastYear === yearStr)[0].fPe : 0;
                this.secondStockPredict['targetEps'] = firstData.vPeEpsData.filter(v => v.fForecastYear === yearStr) ? firstData.vPeEpsData.filter(v => v.fForecastYear === yearStr)[0].fEps : 0; 
                this.companyName1 = firstData.stGoalPriceData.sOrgName;
                this.stockName1 = firstData.stGoalPriceData.sSecuAbbr;
                this.companyName2 = secondData.stGoalPriceData.sOrgName;
                this.stockName2 = secondData.stGoalPriceData.sSecuAbbr;
              } else if(res1.ret === -1){
                console.log('返回投资标的推荐数据为空')
              }
              // 处理产业链数据
              if(res2.ret === 0) {
                let str2 = res2.content;
                let result2 = JSON.parse(str2)
                let sIndustry =  result2.sIndustry;
                let vUpIndustryChainData =  result2.vUpIndustryChainData.filter(v => v !== '');
                let vDownIndustryChainData =  result2.vDownIndustryChainData.filter(v => v !== '');
                if(vUpIndustryChainData.length > 8){
                  vUpIndustryChainData = vUpIndustryChainData.slice(0,9)
                }
                if(vDownIndustryChainData.length > 8){
                  vDownIndustryChainData = vDownIndustryChainData.slice(0,9)
                }
                this.$set(this.chainDetail,"sIndustry", sIndustry)
                this.$set(this.chainDetail, "vUpIndustryChainData", vUpIndustryChainData)
                this.$set(this.chainDetail, "vDownIndustryChainData" ,vDownIndustryChainData)
                console.log("getIndestryChain")
                console.log("this.chainDetail",this.chainDetail);
                this.getAudio();
                
              } else if (res.ret === -1){
                this.chainDetail = {}
                console.log('返回产业链图谱数据为空')
              }
            }).catch(
              (error)=>{
                console.log("请求出错",error);
                if(this.device_id){
                  ApiServe.releasedSevice({ device_id: this.device_id });
                }
              }
            );
          },
          async textTransferAudio(text){
              let params = {
                "sText":text
              };
              // return ApiServe.getIndustryAudio(params);
              return ApiServe.getIndustryAudio(this.newIpPort,'https://zcommtest.mdengta.com/tts/tts',params)
          },
          // 获取旁白
          getAudio(){
            let date = new Date(this.contentDetail.sCreateTime)
            let audio1Text = `从${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日研报中，发现热点概念：${this.chainDetail.sIndustry}`;
            console.log("audio1Text",audio1Text);
            let audio2Text = `${this.chainDetail.sIndustry}的产业链上下游分别有${this.chainDetail.vUpIndustryChainData.join("、")}、${this.chainDetail.vDownIndustryChainData.join("、")}。`
            console.log("audio2Text", audio2Text);
            let audio3Text = `${this.recommendDetail1.stGoalPriceData.sSecuAbbr}的目标价为${this.recommendDetail1.stGoalPriceData.fGoalPriceMin.toFixed(2) === this.recommendDetail1.stGoalPriceData.fGoalPriceMax.toFixed(2) ? (this.recommendDetail1.stGoalPriceData.fGoalPriceMin.toFixed(2)) : (this.recommendDetail1.stGoalPriceData.fGoalPriceMin.toFixed(2) + '至' + this.recommendDetail1.stGoalPriceData.fGoalPriceMax.toFixed(2) ) }元，目标涨幅为${(this.recommendDetail1.stGoalPriceData.fMinPtc*100).toFixed(2) === (this.recommendDetail1.stGoalPriceData.fMaxPtc*100).toFixed(2) ? ((this.recommendDetail1.stGoalPriceData.fMinPtc*100).toFixed(2) + '%' ): ((this.recommendDetail1.stGoalPriceData.fMinPtc*100).toFixed(2) +'%' + '至' + (this.recommendDetail1.stGoalPriceData.fMaxPtc*100).toFixed(2) + '%')  }`;
            console.log("audio3Text",audio3Text);
            let audio4Text = `${this.recommendDetail2.stGoalPriceData.sSecuAbbr}的目标价为${this.recommendDetail2.stGoalPriceData.fGoalPriceMin.toFixed(2) === this.recommendDetail2.stGoalPriceData.fGoalPriceMax.toFixed(2) ? (this.recommendDetail2.stGoalPriceData.fGoalPriceMin.toFixed(2)) : (this.recommendDetail2.stGoalPriceData.fGoalPriceMin.toFixed(2) + '至' + this.recommendDetail2.stGoalPriceData.fGoalPriceMax.toFixed(2) ) }元，目标涨幅为${(this.recommendDetail2.stGoalPriceData.fMinPtc*100).toFixed(2) === (this.recommendDetail2.stGoalPriceData.fMaxPtc*100).toFixed(2) ? ((this.recommendDetail2.stGoalPriceData.fMinPtc*100).toFixed(2) + '%' ): ((this.recommendDetail2.stGoalPriceData.fMinPtc*100).toFixed(2) + '%' + '至' + (this.recommendDetail2.stGoalPriceData.fMaxPtc*100).toFixed(2) + '%') }`;
            console.log("audio4Text",audio4Text);
            let audio5Text = `${this.contentDetail.sPolicy.split("\n")[0]}`;
            console.log("audio5Text",audio5Text);
            let audio6Text = "200+产业链主题，5000+产业链上下游节点，掌握市场规律，窥探股市秘密，尽在产业链写稿";
            console.log("audio6Text",audio6Text);
            let request1 = this.textTransferAudio(audio1Text);
            let request2 = this.textTransferAudio(audio2Text);
            let request3 = this.textTransferAudio(audio3Text);
            let request4 = this.textTransferAudio(audio4Text);
            let request5 = this.textTransferAudio(audio5Text);
            let request6 = this.textTransferAudio(audio6Text);
            Promise.all([request1,request2,request3,request4,request5,request6]).then((value)=>{
              console.log("value",value);
              this.audio1Src = value[0].sSpeechUrl || "";
              this.audio2Src = value[1].sSpeechUrl || "";
              this.audio3Src = value[2].sSpeechUrl || "";
              this.audio4Src = value[3].sSpeechUrl || "";
              this.audio5Src = value[4].sSpeechUrl || "";
              this.audio6Src = value[5].sSpeechUrl || "";
              console.log("audio1Src",this.audio1Src);
              console.log("audio2Src",this.audio2Src);
              console.log("audio3Src",this.audio3Src);
              console.log("audio4Src",this.audio4Src);
              console.log("audio5Src",this.audio5Src);
              console.log("audio6Src",this.audio6Src);
              setTimeout(()=>{
                console.log("this.$refs.audio1",this.$refs.audio1)
                console.log("this.$refs.audio1.duration",this.$refs.audio1.duration)
                this.audioData.push({
                audio_link: this.audio1Src,
                audio_length: this.$refs.audio1.duration,
                scene_duration: Math.ceil(this.$refs.audio1.duration)
                })
                this.audioData.push({
                  audio_link: this.audio2Src,
                  audio_length: this.$refs.audio2.duration,
                  scene_duration: Math.ceil(this.$refs.audio2.duration)
                })
                this.audioData.push({
                  audio_link: this.audio3Src,
                  audio_length: this.$refs.audio3.duration,
                  scene_duration: Math.ceil(this.$refs.audio3.duration)
                })
                this.audioData.push({
                  audio_link: this.audio4Src,
                  audio_length: this.$refs.audio4.duration,
                  scene_duration: Math.ceil(this.$refs.audio4.duration)
                })
                this.audioData.push({
                  audio_link: this.audio5Src,
                  audio_length: this.$refs.audio5.duration,
                  scene_duration: Math.ceil(this.$refs.audio5.duration)
                })
                this.audioData.push({
                  audio_link: this.audio6Src,
                  audio_length: this.$refs.audio6.duration,
                  scene_duration: Math.ceil(this.$refs.audio6.duration)
                })
                console.log("this.audioData",this.audioData)
                this.audioLengthArr = this.audioData.map((item)=>{
                  return item.scene_duration
                });
                console.log("this.audioLengthArr",this.audioLengthArr);
                this.recordTimeAll = this.audioLengthArr.reduce(function(prev,cur,idx,arr){
                  return prev + cur;
                }) + this.audioLengthArr.length * 3;
                console.log("this.recordTimeAll",this.recordTimeAll);
              },2000)
            })
            .catch((err)=>{
              console.log("getAudio error:",err)
              ApiServe.recordreleasePc(this.newIpPort,{
                ip: this.ip,
                port: this.port
              })
            })
            
          },
          handleArr(arr, nowYear) {
            // 转换图表X时间轴的数据
              arr.vPeEpsData.forEach((item) => {
                if(String(item.fForecastYear).includes('A') || String(item.fForecastYear).includes('E')) {
                  return
                }else{
                  if(Number(item.fForecastYear) >= Number(nowYear)) {
                    item.fForecastYear = String(item.fForecastYear).slice(-2)+'E'
                  }else{
                    item.fForecastYear = String(item.fForecastYear).slice(-2)+'A'
                  }
                }
                
              })
              return Object.assign({}, arr)
          },
          surplusRate_init(id,chartData) {
            let chart = this.$echarts.init(
              document.getElementById(id)
            )
            let xData = chartData.vPeEpsData.map(v => v.fForecastYear).reverse()
            let yRateData = chartData.vPeEpsData.map(v => v.fPe).reverse()
            let yAvgData = chartData.vPeEpsData.map(v => v.fAvgPe).reverse()
            // 如果纵坐标数值为0就不画出
            let yData1 = yRateData.map(v => Number(v) === 0 ? '' : v)
            let yData2 = yAvgData.map(v => Number(v) === 0 ? '' : v)
            let data = {
              xData,
              series: [
                {
                  name: chartData.stGoalPriceData.sSecuAbbr,
                  data: yData1
                },
                {
                  name: '行业平均',
                  data: yData2
                }
              ]
            }
            // chart.resize()
            this.drawLine(chart, data, '')
          },
          earnings_init(id,chartData) {
            let chart = this.$echarts.init(
              document.getElementById(id)
            );
            
            let xData = chartData.vPeEpsData.map(v => v.fForecastYear).reverse()
            let yRateData = chartData.vPeEpsData.map(v => v.fEps).reverse()
            let yAvgData = chartData.vPeEpsData.map(v => v.fAvgEps).reverse()
            // 如果纵坐标数值为0就不画出
            let yData1 = yRateData.map(v => Number(v) === 0 ? '' : v)
            let yData2 = yAvgData.map(v => Number(v) === 0 ? '' : v)
            // console.log(`纵坐标数值为0就不画出`, yAvgData)
            let data = {
              xData,
              series: [
                {
                  name: chartData.stGoalPriceData.sSecuAbbr,
                  data: yData1
                },
                {
                  name: '行业平均',
                  data: yData2
                }
              ]
            }
            chart.resize()
            this.drawLine(chart, data, '元/股')
          },
          drawLine(name, data, type) {
            name.setOption({
              grid: {
                left: 80,
                top: 50,
                right: 35,
                bottom: 40,
              },
              tooltip : {
                trigger: 'item',
                show: true,
                position: function(point, params, dom, rect, size) {
                  // 其中point为当前鼠标的位置，size中有两个属性：viewSize和contentSize，分别为外层div和tooltip提示框的大小
                  var x = point[0] //
                  var y = point[1]
                  var viewWidth = size.viewSize[0]
                  var viewHeight = size.viewSize[1]
                  var boxWidth = size.contentSize[0]
                  var boxHeight = size.contentSize[1]
                  var posX = 0 // x坐标位置
                  var posY = 0 // y坐标位置

                  if (x < boxWidth) { // 左边放不开
                    posX = 5
                  } else { // 左边放的下
                    posX = x - boxWidth
                  }

                  if (y < boxHeight) { // 上边放不开
                    posY = 5
                  } else { // 上边放得下
                    posY = y - boxHeight
                  }

                  return [posX, posY]
                },
                formatter:function (params) {
                  return `
                    <div style="font-size: 10px">日期: ${params.name}</div>
                    <div style="font-size: 10px">${params.seriesName}: ${params.value.toFixed(2)}${type}</div>      
                  `
                }
              },
              // 图例组件
              legend: {
                left: 'center',
                top:10,
                textStyle: {
                  color: '#FFFFFF',
                  fontSize: 26
                },
                itemWidth: 50,
                itemHeight: 20,
                itemGap: 65
              },
              // X 轴
              xAxis: [
                {
                  type: 'category',
                  boundaryGap: false,
                  maxInterval: '20%',
                  data: data.xData,
                  axisTick: {
                    show: false,
                  },
                  axisLine: {
                    lineStyle: {
                      type: 'dotted',
                      color: '#FFFFFF', //左边线的颜色                        
                      width: '1', //坐标线的宽度 
                      //利用阴影进行延长
                      shadowOffsetX:20,	//！！！！！！！！！
                      shadowColor:'#FFFFFF'                   
                    }
                  },
                  axisLabel: {
                    align: 'right',
                    interval: '0',
                    textStyle: {
                      fontSize: 24,
                      color: '#FFFFFF', //坐标值得具体的颜色                   
                      fontWeight:400
                    },
                  }
                }
              ],
              // Y 轴
              yAxis: [
                {
                  type: 'value',
                  splitNumber:3,
                  axisTick: {
                    show: false
                  },
                  axisLine: {
                    lineStyle: {
                      type: 'solid',
                      // color: 'transparent', //左边线的颜色                        
                      width: '0' //坐标线的宽度                    
                    }
                  },
                  axisLabel: {
                    interval: '0',
                    textStyle: {
                      fontSize: 24,
                      color: '#FFFFFF', //坐标值得具体的颜色                   
                      fontWeight:400
                    },
                  },
                  splitLine: {
                    lineStyle: {
                      color:'#F1F1F3'
                    }
                  },
                }
              ],
              // 系列列表，每个系列通过type决定自己的图标类型
              series: [
                {
                  name: data.series[0].name,
                  type: 'line',
                  symbol: 'circle',               
                  symbolSize: 16,
                  data: data.series[0].data,
                  lineStyle:{
                    width:4,
                    color:'#F54646',
                    type:'solid'  //'dotted'虚线 'solid'实线
                  },
                  itemStyle: {
                    normal: {
                      color: '#F54646'
                    }
                  }
                },
                {
                  name: data.series[1].name,
                  type: 'line',
                  // symbol: 'circle',               
                  symbolSize: 0,
                  data: data.series[1].data,
                  lineStyle:{
                    width:4,
                    color:'#F54646',
                    type:'dotted'  //'dotted'虚线 'solid'实线
                  },
                  itemStyle: {
                    normal: {
                      color: '#F54646'
                    }
                  }
                }
              ]
            }, true)
          },
          startPlay(){
            this.$refs.bgAudio.volume = 0.4     //背景音乐音量调节（1为最大）
            this.$refs.bgAudio.play()     //背景音乐播放
          },
          endPlay(){

          },
          load() {
            // $("#card1").addClass("none");
            // $("#card3").removeClass("none");
            // this.surplusRate_init("surplusRate1",this.recommendDetail2)
            // this.earnings_init("earnings1",this.recommendDetail2)
            // return
            // 背景音乐开始播放
            // this.startPlay()
            // 卡片1开始
            //首页的视频播放,5s
            // 卡片1播放完+1s后淡出
            setTimeout( ()=> {
              $("#card1").addClass("page__fadeOut");
            }, (this.audioLengthArr[0] + 1) * 1000);
            // 卡片1淡出(2s)完成后卡片2出现
            setTimeout(() => {
              $("#card1").addClass("none");
              $("#card2").removeClass("none");
            }, (this.audioLengthArr[0] + 3) * 1000); 
            // 卡片2播放完+1s后淡出
            setTimeout( ()=> {
              $("#card2").addClass("page__fadeOut");
            }, (this.audioLengthArr[0] + 3 + this.audioLengthArr[1] + 1) * 1000);
            // 卡片2淡出(2s)完成后卡片3出现
            setTimeout( ()=> {
              $("#card2").addClass("none");
              $("#card3").removeClass("none");
              this.surplusRate_init("surplusRate1",this.recommendDetail1)
              this.earnings_init("earnings1",this.recommendDetail1)
            }, (this.audioLengthArr[0] + 3 + this.audioLengthArr[1] + 3) * 1000);
            // 卡片3播放完+1s后淡出
            setTimeout( ()=> {
              $("#card3").addClass("page__fadeOut");
            }, (this.audioLengthArr[0] + 3 + this.audioLengthArr[1] + 3 + this.audioLengthArr[2] + 1) * 1000);
            // 卡片3淡出(2s)完成后卡片4出现
            setTimeout( ()=> {
              $("#card3").addClass("none");
              $("#card4").removeClass("none");
              this.surplusRate_init("surplusRate2",this.recommendDetail2)
              this.earnings_init("earnings2",this.recommendDetail2)
            }, (this.audioLengthArr[0] + 3 + this.audioLengthArr[1] + 3 + this.audioLengthArr[2] + 3) * 1000);
            // 卡片4播放完+1s后淡出
            setTimeout( ()=> {
              $("#card4").addClass("page__fadeOut");
            }, (this.audioLengthArr[0] + 3 + this.audioLengthArr[1] + 3 + this.audioLengthArr[2] + 3 + this.audioLengthArr[3] + 1) * 1000);
            // 卡片4淡出(2s)完成后卡片5出现
            setTimeout( ()=> {
              $("#card4").addClass("none");
              $("#card5").removeClass("none");
              $("#card5_title").removeClass("none");
              $("#card5_text").removeClass("none");
              setTimeout(()=>{
                let height = $("#card5_title").height() + $("#card5_text").height() -$("#card5Container").height() + 100;
                let time = this.audioLengthArr[4] * 1000
                $("#card5Container").animate(
                  {
                    scrollTop:height
                  },
                  time
                );
              },2000)
            }, (this.audioLengthArr[0] + 3 + this.audioLengthArr[1] + 3 + this.audioLengthArr[2] + 3 + this.audioLengthArr[3] + 3) * 1000);
            // 卡片5播放完+1s后淡出
            setTimeout( ()=> {
              $("#card5").addClass("page__fadeOut");
            }, (this.audioLengthArr[0] + 3 + this.audioLengthArr[1] + 3 + this.audioLengthArr[2] + 3 + this.audioLengthArr[3] + 3 + this.audioLengthArr[4] + 1) * 1000);
            // 卡片5淡出(2s)完成后卡片6出现
            setTimeout( ()=> {
              $("#card5").addClass("none");
              $("#card6").removeClass("none");
            }, (this.audioLengthArr[0] + 3 + this.audioLengthArr[1] + 3 + this.audioLengthArr[2] + 3 + this.audioLengthArr[3] + 3 + this.audioLengthArr[4] + 3) * 1000);   //间隔
            setTimeout(()=>{
              $("#card6_bg1").removeClass("none");
            },(this.audioLengthArr[0] + 3 + this.audioLengthArr[1] + 3 + this.audioLengthArr[2] + 3 + this.audioLengthArr[3] + 3 + this.audioLengthArr[4] + 3) * 1000)
            setTimeout(()=>{
              $("#card6_bg2").removeClass("none");
              $("#card6_bg3").removeClass("none");
              $("#card6_bg4").removeClass("none");
              $("#card6_bg5").removeClass("none");
              $("#card6_title").removeClass("none");
              $("#card6_text").removeClass("none");
            },(this.audioLengthArr[0] + 3 + this.audioLengthArr[1] + 3 + this.audioLengthArr[2] + 3 + this.audioLengthArr[3] + 3 + this.audioLengthArr[4] + 3) * 1000)
            // 音频播放
            // let audio0Length = this.audioLengthArr[0]
            // let audio1Length = this.audioLengthArr[1]
            // let audio2Length = this.audioLengthArr[2]
            // let audio3Length = this.audioLengthArr[3]
            // let audio4Length = this.audioLengthArr[4]
            // let arr = [
            //   2,
            //   audio0Length + 3 +2,
            //   audio0Length + 3 + audio1Length + 3 + 2,
            //   audio0Length + 3 + audio1Length + 3 + audio2Length + 5,
            //   audio0Length + 3 + audio1Length + 3 + audio2Length + 3 + audio3Length + 5,
            //   audio0Length + 3 + audio1Length + 3 + audio2Length + 3 + audio3Length + 3 + audio4Length + 5,
            // ];
            // this.startPlay()
            // setTimeout(()=>{
            //   this.$refs.audio1.play()
            // },arr[0]*1000)     
            // setTimeout(()=>{
            //   this.$refs.audio2.play()
            // },arr[1]*1000)     
            // setTimeout(()=>{
            //   this.$refs.audio3.play()
            // },arr[2]*1000)     
            // setTimeout(()=>{
            //   this.$refs.audio4.play()
            // },arr[3]*1000)     
            // setTimeout(()=>{
            //   this.$refs.audio5.play()
            // },arr[4]*1000)     
            // setTimeout(()=>{
            //   this.$refs.audio6.play()
            // },arr[5]*1000)     
            // 总时间
            if(this.ip && this.port){
              setTimeout( ()=> {
                console.log("结束录屏");
                this.endRecord();
              }, this.recordTimeAll * 1000); 
            }
          },
          endRecord(){
            console.log("this.audioLengthArr",this.audioLengthArr);
            let audio0Length = this.audioLengthArr[0]
            let audio1Length = this.audioLengthArr[1]
            let audio2Length = this.audioLengthArr[2]
            let audio3Length = this.audioLengthArr[3]
            let audio4Length = this.audioLengthArr[4]
            let arr = [
              2,
              audio0Length + 3 + 2,
              audio0Length + 3 + audio1Length + 3 + 2,
              audio0Length + 3 + audio1Length + 3 + audio2Length + 3 +2,
              audio0Length + 3 + audio1Length + 3 + audio2Length + 3 + audio3Length + 3 +2,
              audio0Length + 3 + audio1Length + 3 + audio2Length + 3 + audio3Length + 3 + audio4Length + 3 +2,
            ];
            console.log("endRecord arr",arr);
            let audiosOrderList = this.audioData.map((item,index) => {
              return {
                audioLink: item.audio_link,
                timeIndex: arr[index],
                audioLen: item.audio_length,
              }
            });
            console.log("audiosOrderList",audiosOrderList);
            const data = {
              id: this.id,
              model_id: this.model_id,
              videoTotalTime: this.recordTimeAll,
              videoBgm:"https://idtcdn.oss-cn-hangzhou.aliyuncs.com/Cube/VideoBgm/bg.mp3",
              audiosOrderList: audiosOrderList,
            };
            ApiServe.recordstopPc(this.newIpPort, {ip: this.ip, port: this.port})
            .then((res)=>{
              console.log("recordsropPc 返回",res);
              ApiServe.combineaudioswithbgm(this.newIpPort,this.ip,this.port,data)
              .then((res)=>{
                console.log("combineaudioswitchbgm返回",res);
                Api.recordreleasePc(this.newIpPort,{
                  ip: this.ip,
                  port: this.port
                });
              }).catch((err)=>{
                console.log("combineaudioswitchbgm catch err:",err)
                ApiServe.recordreleasePc(this.newIpPort,{
                  ip: this.ip,
                  port: this.port
                })
              })
            }).catch((err)=>{
              console.log("recordsropPc 出错:",err)
              ApiServe.recordreleasePc(this.newIpPort,{
                ip: this.ip,
                port: this.port
              })
            });
          }
        }
    }
</script>

<style lang="less" scoped>
  // 隐藏滚动条
  ::-webkit-scrollbar {
    width: 0px;
  }
  .none{
    display: none!important;
  }
  .verticle-center{
    display: flex;
    align-items: center;
  }
  .page_fadeIn{
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
  }
  .page_fadeInUp{
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
  }
  .page_zoomIn{
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
  }

  .page_pulse{
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
  }
  .page_fadeOut{
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
  }
  .page_delay{
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  
  @beishu:0.67;//ui宽度与录屏设备宽度比,ui设计为1080px(宽)*1920px(高);录屏设备为720px(宽)*1280px(高)
  .content-template{
    width:100%;
    height:100%;
    background:linear-gradient(135deg, #3B4DA7 0%, #220A10 100%);
    .card{
      width:100%;
      height:100%;
      background: linear-gradient(135deg, #3B4DA7 0%, #220A10 100%);
    }
    // 第一页：首页封面
    .card_box_one{
      .title{
        padding-top:620px*@beishu;
        display:flex;
        justify-content: center;
        height: 139px*@beishu;
        font-size: 99px*@beishu;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 173px*@beishu;
        color: #FFFFFF;
        letter-spacing: 30px;
        z-index:99;
        span{
          animation: landIn 0.8s ease-out both;
          color:#FFFFFF;
        }
      }
      .border-box{
        display: flex;
        justify-content: center;
        .border-line{
          margin:20px*@beishu 0 45px*@beishu;
          width:493.73px*@beishu;
          height:12.72px*@beishu;
          background:url("../../assets/img/border_industry.png") 0 0 no-repeat;
          background-size: 100% 100%;
          z-index:99;
        }
      }
      
      .date-time{
        margin:auto;
        width: 281px*@beishu;
        height: 50px*@beishu;
        font-size: 36px*@beishu;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 63px*@beishu;
        color: #FFFFFF;
        z-index:99;
      }
      .bg1-1{
        position: absolute;
        left:101px*@beishu;
        top:152px*@beishu;
        width:380px*@beishu;
        height:309px*@beishu;
        background:url("../../assets/img/bg1_1_industry.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
      .bg1-2{
        position: absolute;
        width:100%;
        height:1816px*@beishu;
        left:0;
        top:0;
        background:url("../../assets/img/bg1_2_industry.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
    }
    // 第二页：产业链图形
    .card_two_box{
      .card2_header{
        display:flex;
        justify-content: center;
        align-items: center;
        :before{
          content: "";
          display: inline-block;
          width:13px*@beishu;
          height:13px*@beishu;
          background:#FFFFFF;
          margin-right:23px*@beishu;
          transform:rotate(45deg)
        }
        :after{
          content: "";
          display: inline-block;
          width:13px*@beishu;
          height:13px*@beishu;
          background:#FFFFFF;
          transform:rotate(45deg);
          margin-left:23px*@beishu;
        }
        .title2{
          height: 84px*@beishu;
          font-size: 60px*@beishu;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
          padding-top:110px*@beishu;
        }
      }
      .card2_content{
        position:absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .chain-pic {
          box-sizing: border-box;
          width: 100%;
          padding: 0 90px*@beishu;
          .chain-top {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            width: 100%;
            padding: 10px 0 6px 0;
            border-bottom: 1PX solid  #01FFF7;
            ::before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 1px;
              height: 24PX*@beishu;
              background-color:  #01FFF7;;
            }
            ::after {
              content: '';
              position: absolute;
              bottom: 0;
              right: 0;
              width: 1px;
              height: 24PX*@beishu;
              background-color:  #01FFF7;
            }
            
          }
          .chain-mid {
            display: flex;
            align-items: center;
            justify-content: center;
            .chain-mid-box {
              display: flex;
              flex-direction: column;
              align-items: center;
              min-width:170px;
              .upstream-box{
                width:100%;
                display:flex;
                .upstream-left{
                  height:134px*@beishu;
                  width:50%;
                }
                .upstream-right{
                  height:134px*@beishu;
                  width:50%;
                  display: flex;
                  align-items: center;
                  box-sizing: border-box;
                  border-left: 1px solid #01FFF7;
                  font-size: 45px*@beishu;
                  font-family: PingFang SC;
                  font-weight: bold;
                  color: #FFFFFF;
                  padding-left:20px;
                }
              }
              .production {
                position: relative;
                padding:8px*@beishu 53px*@beishu;
                height: 76.44px*@beishu;
                background: #F54646;
                border-radius: 40px*@beishu;
                font-size: 45px*@beishu;
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 76.44px*@beishu;
                color: #FFFFFF;
                opacity: 1;
              }
              .downstream-box{
                width:100%;
                display:flex;
                .downstream-left{
                  height:134px*@beishu;
                  width:50%;
                }
                .downstream-right{
                  height:134px*@beishu;
                  width:50%;
                  display: flex;
                  align-items: center;
                  box-sizing: border-box;
                  border-left: 1px solid #01FFF7;
                  font-size: 45px*@beishu;
                  font-family: PingFang SC;
                  font-weight: bold;
                  color: #FFFFFF;
                  padding-left:20px;
                }
              }
            }
            
            
          }
          .chain-btm {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            width: 100%;
            padding: 10px 0 6px 0;
            border-top: 1px solid  #01FFF7;
            ::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 1px;
              height: 24PX*@beishu;
              background-color: #01FFF7;
            }
            ::after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              width: 1px;
              height: 24PX*@beishu;
              background-color: #01FFF7;
            }
          }
          
          .chain-top > span, .chain-btm > span {
            // min-width: 30%;
            text-align: center;
            padding: 6px*@beishu 42px*@beishu;
            margin: 8px 0;
            font-size: 45px*@beishu;
            font-family: PingFang SC;
            font-weight: bold;
            border-radius: 20px;
            border: 1px solid #F54646;
            color: #FFFFFF;
            opacity: 1;
          }
          .title{
            width:100%;
            box-sizing: border-box;
            padding-top: 126px*@beishu;
            font-size:39px*@beishu;
            line-height:70px*@beishu;
            font-weight:400;
            color:#FFFFFF;
          }
          
          
        }
      }
      
      
      .bg2-1{
        position: absolute;
        left:101px*@beishu;
        top:152px*@beishu;
        width:380px*@beishu;
        height:309px*@beishu;
        background:url("../../assets/img/bg2_1_industry.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
      .bg2-2{
        position: absolute;
        width:762px*@beishu;
        height:685.35px*@beishu;
        right:0;
        bottom:36px*@beishu;
        background:url("../../assets/img/bg2_2_industry.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
    }
    // 第三页：个股推荐
    .card_three_box{
      .card3_header{
        display:flex;
        justify-content: center;
        align-items: center;
        :before{
          content: "";
          display: inline-block;
          width:13px*@beishu;
          height:13px*@beishu;
          background:#FFFFFF;
          margin-right:23px*@beishu;
          transform:rotate(45deg)
        }
        :after{
          content: "";
          display: inline-block;
          width:13px*@beishu;
          height:13px*@beishu;
          background:#FFFFFF;
          transform:rotate(45deg);
          margin-left:23px*@beishu;
        }
        .title3{
          height: 84px*@beishu;
          font-size: 60px*@beishu;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
          padding-top:103px*@beishu;
          
        }
      }
      .card3_stock_info{
        margin-top:36px*@beishu;
        .top{
          width:908px*@beishu;
          margin:0 auto;
          display:flex;
          flex-direction:column;
          align-items: center;
          background: rgba(245, 70, 70, 0.55);
          border: 2px solid #FF0000;
          border-radius: 6px;
          .stock-name{
            position:relative;
            display:flex;
            align-items: center;
            flex-direction:column;
            .name{
              margin-top:20px*@beishu;
              font-size:46px*@beishu;
              font-weight:bold;
              color:#FFFFFF;
            }
            .code{
              font-size:30px*@beishu;
              font-weight:500;
              color:#FFFFFF;
            }
          }
          .stock-price-increase{
            display:flex;
            justify-content: center;
            margin-top:23px*@beishu;
            margin-bottom:29px*@beishu;
            .stock-price{
              position:relative;
              padding: 0 20px*@beishu;
              height:67px*@beishu;
              background: #F54646;
              border-radius:4px*@beishu;
              margin-right:44px*@beishu;
              .name{
                font-size:32px*@beishu;
                font-weight:bold;
                color:#FFFFFF;
                margin-right:20px*@beishu;
                line-height:67px*@beishu;
              }
              .code{
                font-size:32px*@beishu;
                font-weight:bold;
                color:#FFFFFF;
                line-height:67px*@beishu;
              }
            }
            .stock-increase{
              position:relative;
              padding: 0 20px*@beishu;
              height:67px*@beishu;
              background: #F54646;
              border-radius:4px*@beishu;
              .name{
                font-size:32px*@beishu;
                font-weight:bold;
                color:#FFFFFF;
                margin-right:20px*@beishu;
                line-height:67px*@beishu;
              }
              .code{
                font-size:32px*@beishu;
                font-weight:bold;
                color:#FFFFFF;
                line-height:67px*@beishu;
              }
            }
          }
        }
        .bottom{
          display: flex;
          justify-content: center;
          margin-top:23px*@beishu;
          .text{
            width:907px*@beishu;
            font-size:39px*@beishu;
            line-height:56px*@beishu;
            font-weight:400;
            color:#FFFFFF;
          }
        }
      }
      .analyse-chart{
        display:flex;
        flex-direction: column;
        align-items: center;
        .chart-one{
          width:928px*@beishu;
          .chart-title{
            font-size:48px*@beishu;
            font-weight:bold;
            color:#FFFFFF;
            text-align: center;
          }
          .chart-box{
            width:100%;
            height:500px*@beishu;
          }

        }
        .chart-two{
          width:928px*@beishu;
          .chart-title{
            font-size:48px*@beishu;
            font-weight:bold;
            color:#FFFFFF;
            text-align: center;
          }
          .chart-box{
            width:100%;
            height:500px*@beishu;
          }
        }
        .data-source{
          font-size:39px*@beishu;
          font-weight:400;
          line-height:173px;
          color:#FFFFFF;
          opacity:0.29;
        }
      }
      .bg3-1{
        position: absolute;
        right:84px*@beishu;
        top:152px*@beishu;
        width:380px*@beishu;
        height:309px*@beishu;
        background:url("../../assets/img/bg3_1_industry.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
      .bg3-2{
        position: absolute;
        width:778px*@beishu;
        height:684px*@beishu;
        left:0;
        bottom:35px*@beishu;
        background:url("../../assets/img/bg3_2_industry.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
    }
    // 第四页：个股推荐
    .card_four_box{
      .card4_header{
        display:flex;
        justify-content: center;
        align-items: center;
        :before{
          content: "";
          display: inline-block;
          width:13px*@beishu;
          height:13px*@beishu;
          background:#FFFFFF;
          margin-right:23px*@beishu;
          transform:rotate(45deg)
        }
        :after{
          content: "";
          display: inline-block;
          width:13px*@beishu;
          height:13px*@beishu;
          background:#FFFFFF;
          transform:rotate(45deg);
          margin-left:23px*@beishu;
        }
        .title4{
          height: 84px*@beishu;
          font-size: 60px*@beishu;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 173px*@beishu;
          color: #FFFFFF;
          padding-top:103px*@beishu;
          
        }
      }
      .card4_stock_info{
        margin-top:36px*@beishu;
        .top{
          width:908px*@beishu;
          margin:0 auto;
          display:flex;
          flex-direction:column;
          align-items: center;
          background: rgba(245, 70, 70, 0.55);
          border: 2px solid #FF0000;
          border-radius: 6px;
          .stock-name{
            position:relative;
            display:flex;
            align-items: center;
            flex-direction:column;
            .name{
              margin-top:20px*@beishu;
              font-size:46px*@beishu;
              font-weight:bold;
              color:#FFFFFF;
            }
            .code{
              font-size:30px*@beishu;
              font-weight:500;
              color:#FFFFFF;
            }
          }
          .stock-price-increase{
            display:flex;
            justify-content: center;
            margin-top:23px*@beishu;
            margin-bottom:29px*@beishu;
            .stock-price{
              position:relative;
              padding: 0 20px*@beishu;
              height:67px*@beishu;
              background: #F54646;
              border-radius:4px*@beishu;
              margin-right:44px*@beishu;
              .name{
                font-size:32px*@beishu;
                font-weight:bold;
                color:#FFFFFF;
                margin-right:20px*@beishu;
                line-height:67px*@beishu;
              }
              .code{
                font-size:32px*@beishu;
                font-weight:bold;
                color:#FFFFFF;
                line-height:67px*@beishu;
              }
            }
            .stock-increase{
              position:relative;
              padding: 0 20px*@beishu;
              height:67px*@beishu;
              background: #F54646;
              border-radius:4px*@beishu;
              .name{
                font-size:32px*@beishu;
                font-weight:bold;
                color:#FFFFFF;
                margin-right:20px*@beishu;
                line-height:67px*@beishu;
              }
              .code{
                font-size:32px*@beishu;
                font-weight:bold;
                color:#FFFFFF;
                line-height:67px*@beishu;
              }
            }
          }
        }
        .bottom{
          display: flex;
          justify-content: center;
          margin-top:23px*@beishu;
          .text{
            width:907px*@beishu;
            font-size:39px*@beishu;
            line-height:56px*@beishu;
            font-weight:400;
            color:#FFFFFF;
          }
        }
      }
      .analyse-chart{
        display:flex;
        flex-direction: column;
        align-items: center;
        .chart-one{
          width:928px*@beishu;
          .chart-title{
            font-size:48px*@beishu;
            font-weight:bold;
            color:#FFFFFF;
            text-align: center;
          }
          .chart-box{
            width:100%;
            height:500px*@beishu;
          }

        }
        .chart-two{
          width:928px*@beishu;
          .chart-title{
            font-size:48px*@beishu;
            font-weight:bold;
            color:#FFFFFF;
            text-align: center;
          }
          .chart-box{
            width:100%;
            height:500px*@beishu;
          }
        }
        .data-source{
          font-size:39px*@beishu;
          font-weight:400;
          line-height:173px;
          color:#FFFFFF;
          opacity:0.29;
        }
      }
      .bg4-1{
        position: absolute;
        right:84px*@beishu;
        top:152px*@beishu;
        width:380px*@beishu;
        height:309px*@beishu;
        background:url("../../assets/img/bg3_1_industry.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
      .bg4-2{
        position: absolute;
        width:778px*@beishu;
        height:684px*@beishu;
        left:0;
        bottom:35px*@beishu;
        background:url("../../assets/img/bg3_2_industry.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
    }
    // 第五页：政策解读
    .card_five_box{
      overflow-y: auto;
      .card5_header{
        box-sizing: border-box;
        display:flex;
        justify-content: center;
        align-items: center;
        :before{
          content: "";
          display: inline-block;
          width:13px*@beishu;
          height:13px*@beishu;
          background:#FFFFFF;
          margin-right:23px*@beishu;
          transform:rotate(45deg)
        }
        :after{
          content: "";
          display: inline-block;
          width:13px*@beishu;
          height:13px*@beishu;
          background:#FFFFFF;
          transform:rotate(45deg);
          margin-left:23px*@beishu;
        }
        .title5{
          height: 84px*@beishu;
          font-size: 60px*@beishu;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
          padding-top:103px*@beishu;
          
        }
      }
      .card5_content{
        box-sizing: border-box;
        margin: 0 auto;
        box-sizing: border-box;
        padding-top:68px*@beishu;
        padding-bottom:68px*@beishu;
        padding-left:80px*@beishu;
        padding-right:80px*@beishu;
        .text{
          text-indent: 2em;
          font-size: 44px*@beishu;
          font-weight: 400;
          line-height: 72px*@beishu;
          color: #FFFFFF;
        }
      }
      .bg5-1{
        position: absolute;
        right:84px*@beishu;
        top:152px*@beishu;
        width:380px*@beishu;
        height:309px*@beishu;
        background:url("../../assets/img/bg5_1_industry.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
      .bg5-2{
        position: absolute;
        width:778px*@beishu;
        height:684px*@beishu;
        left:0;
        bottom:35px*@beishu;
        background:url("../../assets/img/bg5_2_industry.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
    }
    // 第六页：尾页封面
    .card_six_box{
      .title6{
        position: absolute;
        height: 139px*@beishu;
        font-size: 99px*@beishu;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 173px*@beishu;
        color: #FFFFFF;
        letter-spacing: 60px*@beishu;
        left:330px*@beishu;
        top:760px*@beishu;
        z-index:99;
      }
      .content-text{
        position: absolute;
        top:1136px*@beishu;
        left:50%;
        display: flex;
        flex-direction:column;
        align-items: center;
        transform: translate(-50%);
        z-index:99;
        .text1{
          font-size: 36px*@beishu;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 63px*@beishu;
          color: #FFFFFF;
          .text-num{
            color:#01FFF7;
            font-size:60px*@beishu;
            margin-right:20px*@beishu;
          }
        }
      }
      .bg6-1{
        position: absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        background:url("../../assets/img/bg6_1_industry.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
      .bg6-2{
        position: absolute;
        left:205px*@beishu;
        top:518px*@beishu;
        width:669px*@beishu;
        height:669px*@beishu;
        background:url("../../assets/img/bg6_2_industry.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
      .bg6-3{
        position: absolute;
        left:280px*@beishu;
        top:593px*@beishu;
        width:520px*@beishu;
        height:520px*@beishu;
        background:url("../../assets/img/bg6_3_industry.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
      .bg6-4{
        position: absolute;
        left:368px*@beishu;
        top:681px*@beishu;
        width:344px*@beishu;
        height:344px*@beishu;
        background:url("../../assets/img/bg6_4_industry.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
      .bg6-5{
        position: absolute;
        left:463px*@beishu;
        top:774px*@beishu;
        width:154px*@beishu;
        height:158px*@beishu;
        background:url("../../assets/img/bg6_5_industry.png") 0 0 no-repeat;
        background-size: 100% 100%;
      }
    }
  }
</style>